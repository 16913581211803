import React from "react";


class CodePage extends React.Component {
    constructor(props) {
        super(props);
        if (typeof window !== `undefined`) {
            window.location.href = "https://alexwks.notion.site/WorldSkill-Mapping-289a5d2c2fdc4482b0cfd35c21271f38"
        }
    }
    render() {
        return (
            <div></div>
                )
    }
}
export default CodePage
